import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  ArrowBack,
  ArrowDropDown,
  ArrowDropUp,
  ArrowOutward,
  CallReceived,
  CancelOutlined,
  ChevronRight,
  FormatListBulleted,
  List,
  Sort,
  TrendingDown,
  TrendingUp,
} from "@mui/icons-material";

import { AlertContext } from "App";
import NoData from "pages/NoData/NoData";
import Button from "components/ui/Button/Button";
import InvestOptions from "components/common/InvestOptions/InvestOptions";
import BottomSheet from "components/common/BottomSheet/BottomSheet";
import MobileNav from "components/common/MobileNav/MobileNav";
import Note from "components/common/Note/Note";
import RedeemForm from "components/forms/RedeemForm";
import MiniLoader from "components/common/MiniLoader/MiniLoader";
import useDocumentTitle from "hooks/useDocumentTitle";
import { credentialAxios, publicAxios } from "utils/api-calls";
import { ANIMATION_STYLES, API_ENDPOINTS } from "utils/constants";
import {
  capitalizeText,
  convertNumberToCurrency,
  mapServerErrorsToLocal,
  sortObjectDataByProperty,
} from "utils/functions";

import clickIcon from "assets/icons/icons8-click-66.png";

import {
  InvestmentsOverviewCont,
  InvestmentsOverviewHead,
  InvestmentOverviewFilter,
  InvestmentsOverviewList,
  InvestmentDetailCard,
  LinkType,
  InvestmentCont,
  UserFieldsCont,
  InvestmentsTableCont,
  MobileMainContainer,
  MobileContainer,
  MobileInvestmentCardComp,
  NoneSelected,
  InvestmentFilterTitle,
  InvestmentFilterTitleName,
  InvestmentTypeTag,
  SelectedInvestmentData,
  MoreInfoCard,
  MoreInfoWrapper,
  RedeemCard,
  LabelAndValue,
  MoreInfoButton,
} from "./Investment.styles";
import {
  CloseButton,
  MobileView,
  StyledAlignCenter,
  StyledCentering,
} from "styles/common-styled-components";
import { TagComp } from "pages/SingleMutualFund/SingleMutualFund.styles";
import { RightContSkeleton } from "pages/SingleMutualFund/SingleMutualFund.skeletons";
import ConditionalMutateLoader from "components/utility/ConditionalMutateLoader";

const TABLE_HEAD = ["SCHEME NAME", "UNITS", "RETURNS", "CURRENT"];

const Investments = () => {
  useDocumentTitle("Your Investments - WiseBulls");
  const navigate = useNavigate();

  const [selectedInvestmentDetails, setSelectedInvestmentDetails] =
    useState(null);

  const [showRedeem, setShowRedeem] = useState(false);
  const [highlightRedeem, setHighlightRedeem] = useState(true);

  const getInvestmentDetails = () => {
    return credentialAxios.get(API_ENDPOINTS.getInvestments);
  };

  const { data: investmentsData, isLoading } = useQuery(
    "investments",
    getInvestmentDetails,
    { refetchOnWindowFocus: false }
  );

  const [loadingMfData, setLoadingMfData] = useState(false);
  const [mfData, setMfData] = useState(null);

  const toggleHighlightRedeem = () => {
    setHighlightRedeem(true);
    setTimeout(() => {
      setHighlightRedeem(false);
    }, 3000);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedInvestmentDetails) {
        try {
          const response = await publicAxios.get(
            API_ENDPOINTS.getMFDetails(selectedInvestmentDetails.scheme_id)
          );
          setMfData(response.data);
        } catch (error) {
          console.error("Error fetching mutual fund details:", error);
        } finally {
          setLoadingMfData(false);
        }
      }
    };

    fetchData();
  }, [selectedInvestmentDetails]);

  return isLoading ? (
    <></>
  ) : (
    <>
      <MobileNav headerTitle={"Investments"} heightBelow="0" />

      {investmentsData.data.data.length === 0 ? (
        <>
          <MobileView>
            <div style={{ height: "10rem" }} />
          </MobileView>
          <NoData
            message={"You have no investments yet"}
            subMessage={"Start exploring Mutual Funds"}
            redirecting="Explore Mutual Funds"
            onRedirect={() => {
              navigate("/mutual-funds");
            }}
          />
        </>
      ) : (
        <>
          <motion.div
            style={{ display: "flex", gap: "6rem" }}
            initial={ANIMATION_STYLES.opacity.initial}
            animate={ANIMATION_STYLES.opacity.animate}
            exit={ANIMATION_STYLES.opacity.exit}
          >
            <InvestmentsOverviewCont>
              <InvestmentsTable
                handleHighlightRedeem={toggleHighlightRedeem}
                investmentsData={investmentsData}
                selectedInvestmentDetails={selectedInvestmentDetails}
                setSelectedInvestmentDetails={(data) => {
                  setLoadingMfData(true);
                  setSelectedInvestmentDetails(data);
                }}
                setShowRedeem={setShowRedeem}
              />
            </InvestmentsOverviewCont>

            <InvestmentCont>
              {!selectedInvestmentDetails ? (
                <UserFieldsCont>
                  <div>
                    <NoneSelected>
                      <div
                        style={{
                          width: "100%",
                          height: "4rem",
                          backgroundColor: "rgba(32, 178, 171, 0.2)",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            bottom: "-1rem",
                            right: "1rem",
                          }}
                        >
                          <LazyLoadImage
                            src={clickIcon}
                            alt={"mouse click"}
                            width={"50%"}
                          />
                        </div>
                      </div>
                    </NoneSelected>
                    <h3 style={{ fontSize: "1.6rem", marginBottom: "1rem" }}>
                      Select your Mutual Fund
                    </h3>
                    <p style={{ fontSize: "1.2rem" }}>
                      to view your investing options
                    </p>
                  </div>
                </UserFieldsCont>
              ) : (
                <div style={{ position: "fixed" }}>
                  {loadingMfData ? (
                    <RightContSkeleton />
                  ) : showRedeem ? (
                    <RedeemCard
                      key={highlightRedeem ? "highlighted" : "normal"}
                      isHighlighted={highlightRedeem}
                    >
                      <ArrowBack
                        style={{ fontSize: "2.4rem", cursor: "pointer" }}
                        onClick={() => {
                          setShowRedeem(false);
                        }}
                      />
                      <RedeemForm
                        state={selectedInvestmentDetails}
                        mfData={mfData}
                      />
                    </RedeemCard>
                  ) : (
                    <div>
                      <InvestOptions
                        data={mfData}
                        additionalPurchase
                        folio_number={selectedInvestmentDetails.folio_number}
                        noSip
                        noSipMessage="Additional Purchase in SIP is not available right now"
                      />
                    </div>
                  )}
                </div>
              )}
            </InvestmentCont>
          </motion.div>

          {/* MOBILE */}
          <MobileInvestments
            data={investmentsData.data.data}
            selectedInvestmentDetails={selectedInvestmentDetails}
            mfData={mfData}
            loadingMfData={loadingMfData}
            setSelectedInvestmentDetails={(data) => {
              setLoadingMfData(true);
              setSelectedInvestmentDetails(data);
            }}
          />
        </>
      )}
    </>
  );
};

export const InvestmentsTable = ({
  investmentsData,
  setSelectedInvestmentDetails,
  selectedInvestmentDetails,
  setShowRedeem,
  handleHighlightRedeem,
}) => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useContext(AlertContext);

  const [sortData, setSortData] = useState(investmentsData.data.data);

  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const [selectedSort, setSelectedSort] = useState({
    label: "SCHEME NAME",
    order: "DOWN",
  });

  const sortDataFunc = () => {
    const dataWithCurrentAndReturns = sortData.map((curr) => {
      curr.current = curr.units * curr.nav;
      curr.returns = curr.units * curr.nav - curr.invested_amount;
      return curr;
    });
    let filteredData = sortObjectDataByProperty(
      dataWithCurrentAndReturns,
      selectedSort.order,
      selectedSort.label === "SCHEME NAME" ? "STRING" : "NUMBER",
      selectedSort.label.toLowerCase().split(" ").join("_")
    );
    setSortData(filteredData);
  };

  const handleSelectedSort = (curr) => {
    if (selectedSort.label === curr) {
      setSelectedSort({
        ...selectedSort,
        order: selectedSort.order === "UP" ? "DOWN" : "UP",
      });
      sortDataFunc();
    } else {
      setSelectedSort({
        label: curr,
        order: "DOWN",
      });
    }
  };

  return (
    <InvestmentsTableCont>
      <InvestmentsOverviewHead>
        <div
          style={{
            textAlign: "center",
            paddingRight: "4.8rem",
            paddingLeft: "3rem",
          }}
        >
          <p
            style={{
              fontSize: "1.4rem",
              textAlign: "left",
            }}
          >
            Total Current Value
          </p>
          <StyledAlignCenter style={{ marginBottom: "1rem" }}>
            <span
              style={{
                fontSize: "2.6rem",
                fontWeight: "500",
                textAlign: "left",
                color:
                  investmentsData.data.summary.current_value >=
                  investmentsData.data.summary.total_investment
                    ? "var(--themeColor)"
                    : "red",
              }}
            >
              {convertNumberToCurrency(
                investmentsData.data.summary.current_value.toFixed(2)
              )}
            </span>
            <StyledAlignCenter style={{}}>
              <span style={{}}>
                ({investmentsData.data.summary.total_returns_percent}%)
              </span>
              {investmentsData.data.summary.gaining ? (
                <TrendingUp
                  style={{ color: "var(--themeColor)", fontSize: "2.4rem" }}
                />
              ) : (
                <TrendingDown
                  style={{
                    color: "var(--errorTextColor)",
                    fontSize: "2.4rem",
                  }}
                />
              )}
            </StyledAlignCenter>
          </StyledAlignCenter>
        </div>
        <div
          style={{
            paddingRight: "3rem",
            paddingLeft: "6rem",
            display: "flex",
            gap: "2rem",
            alignItems: "center",
          }}
        >
          <div
            style={{
              borderLeft: "1px solid #ddd",
              paddingLeft: "1rem",
            }}
          >
            <p
              style={{
                fontSize: "1.4rem",
                marginBottom: "1rem",
                fontWeight: "600",
              }}
            >
              Investments at cost
            </p>
            <p
              style={{
                fontSize: "1.4rem",
                fontWeight: "600",
                marginBottom: "1rem",
              }}
            >
              Returns
            </p>
            <p style={{ fontSize: "1.4rem", fontWeight: "600" }}>XIRR</p>
          </div>

          <div style={{}}>
            <p
              style={{
                fontSize: "1.4rem",
                marginBottom: "1rem",
              }}
            >
              <span>
                {convertNumberToCurrency(
                  investmentsData.data.summary.total_investment.toFixed(0)
                )}
              </span>
            </p>

            <p
              style={{
                fontSize: "1.4rem",
                marginBottom: "1rem",
                color:
                  investmentsData.data.summary.current_value >
                  investmentsData.data.summary.total_investment
                    ? "var(--themeColor)"
                    : "red",
              }}
            >
              <span>
                {convertNumberToCurrency(
                  investmentsData.data.summary.total_returns.toFixed(0)
                )}
              </span>
            </p>
            <p>
              <span
                style={{
                  fontSize: "1.4rem",
                  color:
                    investmentsData.data.summary.current_value >
                    investmentsData.data.summary.total_investment
                      ? "var(--themeColor)"
                      : "red",
                }}
              >
                {investmentsData.data.summary.overall_xirr}
              </span>
            </p>
          </div>
        </div>
      </InvestmentsOverviewHead>
      <InvestmentOverviewFilter>
        {TABLE_HEAD.map((curr, i) => {
          return (
            <InvestmentFilterTitle
              onClick={() => handleSelectedSort(curr)}
              key={i}
              style={{
                justifySelf: curr === "SCHEME NAME" ? "start" : "end",
              }}
            >
              <InvestmentFilterTitleName
                color={selectedSort.label === curr ? "dark" : "light"}
              >
                {curr}
              </InvestmentFilterTitleName>
              {selectedSort.label === curr && selectedSort.order === "UP" ? (
                <ArrowDropUp
                  style={{
                    fontSize: "2.4rem",
                    color:
                      selectedSort.label === curr
                        ? "var(--themeColor)"
                        : "#b0b2ba",
                  }}
                />
              ) : (
                <ArrowDropDown
                  style={{
                    fontSize: "2.4rem",
                    color:
                      selectedSort.label === curr
                        ? "var(--themeColor)"
                        : "#b0b2ba",
                  }}
                />
              )}
            </InvestmentFilterTitle>
          );
        })}
      </InvestmentOverviewFilter>
      <InvestmentsOverviewList>
        {sortData.map((investment, i) => {
          console.log(investment);
          return (
            <InvestmentDetailCard
              key={i}
              active={
                selectedInvestmentDetails?.folio_number ===
                  investment.folio_number &&
                selectedInvestmentDetails.scheme_id === investment.scheme_id
              }
              onClick={() => {
                if (
                  selectedInvestmentDetails?.folio_number ===
                    investment.folio_number &&
                  selectedInvestmentDetails.scheme_id === investment.scheme_id
                ) {
                  return;
                } else {
                  setSelectedInvestmentDetails(investment);
                }
              }}
            >
              <div>
                <LinkType
                  onClick={() =>
                    navigate(`/mutual-funds/${investment.scheme_id}`)
                  }
                >
                  {capitalizeText(investment.scheme_name)}
                </LinkType>
                <p style={{ color: "#6a6a6a", fontSize: "1.2rem" }}>
                  XIRR: {investment?.xirr}
                </p>
                <TagComp
                  style={{
                    fontSize: "1.2rem",
                    marginTop: "1.4rem",
                    display: "inline-block",
                    padding: "1rem 2rem",
                    border: "1px solid #ccc",
                  }}
                >
                  {investment.order_type}
                  {investment?.is_sip_cancelled ? (
                    <span> - Cancelled</span>
                  ) : null}
                </TagComp>
              </div>
              <p style={{ fontSize: "1.3rem", marginRight: "1rem" }}>
                {investment.units}
              </p>
              <div
                style={{
                  width: "100%",
                  textAlign: "right",
                  fontSize: "1.3rem",
                  marginRight: "1rem",
                  color:
                    (
                      investment.nav * investment.units -
                      investment.invested_amount
                    ).toFixed(0) <= 0
                      ? "#eb5b3c"
                      : "",
                }}
              >
                <p style={{}}>
                  ₹
                  {(
                    investment.nav * investment.units -
                    investment.invested_amount
                  ).toFixed(0)}{" "}
                </p>
                <p>
                  (
                  {(
                    ((investment.nav * investment.units -
                      investment.invested_amount) /
                      investment.invested_amount) *
                    100
                  ).toFixed(2)}
                  %)
                </p>
              </div>
              <div style={{ marginRight: "1rem", textAlign: "right" }}>
                <p style={{ fontSize: "1.3rem", marginBottom: "4rem" }}>
                  ₹{(investment.nav * investment.units).toFixed(0)}
                </p>
                <StyledAlignCenter
                  onClick={() => setShowMoreInfo(true)}
                  style={{
                    color: "var(--themeColor)",
                    cursor: "pointer",
                    textAlign: "right",
                    justifyContent: "flex-end",
                  }}
                >
                  <p>More Info</p>
                  <ChevronRight style={{ fontSize: "2.4rem" }} />
                </StyledAlignCenter>
              </div>
              <MoreInfoWrapper
                open={
                  showMoreInfo &&
                  selectedInvestmentDetails &&
                  selectedInvestmentDetails.folio_number ===
                    investment.folio_number &&
                  selectedInvestmentDetails.scheme_id === investment.scheme_id
                }
              >
                <CloseButton
                  style={{ right: 0 }}
                  onClick={() => setShowMoreInfo(false)}
                />
                <MoreInfoCard>
                  <SelectedInvestmentData>
                    <LabelAndValue>
                      <label>Folio Number</label>
                      <p>{selectedInvestmentDetails?.folio_number}</p>
                    </LabelAndValue>
                    <LabelAndValue>
                      <label>NAV</label>
                      <p style={{ fontSize: "1.3rem" }}>
                        {selectedInvestmentDetails?.nav}
                      </p>
                    </LabelAndValue>
                    <LabelAndValue>
                      <label>Invested Amount</label>
                      <p>{selectedInvestmentDetails?.invested_amount}</p>
                    </LabelAndValue>
                    {/* {selectedInvestmentDetails?.order_type === "SIP" && (
                      <LabelAndValue>
                        <label>Started date</label>
                        <p>{selectedInvestmentDetails?.sip_regn_date}</p>
                      </LabelAndValue>
                    )} */}
                  </SelectedInvestmentData>

                  <div style={{}}>
                    <MoreInfoButton
                      onClick={() => {
                        setShowRedeem(true);
                        handleHighlightRedeem(true);
                      }}
                    >
                      <CallReceived />
                      Redeem
                    </MoreInfoButton>
                    <MoreInfoButton
                      onClick={() => {
                        setShowRedeem(false);
                      }}
                    >
                      <ArrowOutward />
                      Invest More
                    </MoreInfoButton>
                    <MoreInfoButton
                      onClick={() => {
                        navigate("/investment-details", {
                          state: selectedInvestmentDetails,
                        });
                      }}
                    >
                      <List />
                      More Investment Details
                    </MoreInfoButton>
                    {selectedInvestmentDetails?.order_type === "SIP" &&
                    !selectedInvestmentDetails?.is_sip_cancelled ? (
                      <MoreInfoButton
                        onClick={() => {
                          openModal({
                            comp: (
                              <CancelSip
                                closeModal={closeModal}
                                data={selectedInvestmentDetails}
                              />
                            ),
                          });
                        }}
                      >
                        <CancelOutlined />
                        Cancel SIP
                      </MoreInfoButton>
                    ) : null}
                  </div>
                </MoreInfoCard>
              </MoreInfoWrapper>
            </InvestmentDetailCard>
          );
        })}
      </InvestmentsOverviewList>
    </InvestmentsTableCont>
  );
};

const MobileInvestments = ({
  data,
  mfData,
  selectedInvestmentDetails,
  loadingMfData,
  setSelectedInvestmentDetails,
}) => {
  const { closeModal } = useContext(AlertContext);

  const SORT_OPTIONS = [
    {
      label: "Alphabetically",
      ascendingLabel: "A - Z",
      descendingLabel: "Z - A",
      property: "scheme_name",
    },
    {
      label: "Current",
      ascendingLabel: "High - Low",
      descendingLabel: "Low - High",
      property: "current",
    },
    {
      label: "Returns",
      ascendingLabel: "High - Low",
      descendingLabel: "Low - High",
      property: "returns",
    },
    {
      label: "Growth",
      ascendingLabel: "High - Low",
      descendingLabel: "Low - High",
      property: "growth",
    },
  ];

  const navigation = useNavigate();
  const [sortData, setSortData] = useState(data);
  const [sortOption, setSortOption] = useState({
    label: "scheme_name",
    order: "DOWN",
  });

  const [showBottomSheet, setShowBottomSheet] = useState(false);

  const [showSortingOptions, setShowSortingOptions] = useState(false);

  // const [selectedInvestmentDetails, setSelectedInvestmentDetails] =
  //   useState(null);

  const [mobileCancelSip, setMobileCancelSip] = useState(false);

  const handleSort = (sortType) => {
    setShowSortingOptions(false);
    setSortOption(sortType);

    const dataWithCurrentAndReturns = sortData.map((curr) => {
      let current = curr.units * curr.nav;
      curr.current = current;
      curr.returns = current - curr.invested_amount;
      curr.growth = (
        ((current - curr.invested_amount) / curr.invested_amount) *
        100
      ).toFixed(2);

      return curr;
    });

    let sortedData = sortObjectDataByProperty(
      dataWithCurrentAndReturns,
      sortType.order,
      sortType.label === "scheme_name" ? "STRING" : "NUMBER",
      sortType.label
    );

    setSortData(sortedData);
  };

  return (
    <MobileMainContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "4rem",
        }}
      >
        <div
          onClick={() => setShowSortingOptions(true)}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Sort style={{ fontSize: "2rem" }} />

          <p style={{ fontSize: "1.4rem", fontWeight: "bold" }}>Sort By</p>
        </div>
        <p style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
          {sortOption.label === "returns" ? "Returns" : "Current"}
        </p>
      </div>

      <MobileContainer>
        <>
          {sortData.map((investment, index) => {
            return (
              <MobileInvestmentCardComp
                key={index}
                onClick={() => {
                  setSelectedInvestmentDetails(investment);
                  setShowBottomSheet(true);
                }}
              >
                <div>
                  <p
                    style={{
                      fontWeight: "500",
                      fontSize: "1.2rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {investment.scheme_name}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <InvestmentTypeTag style={{}}>
                      {investment.order_type}
                    </InvestmentTypeTag>
                    <p style={{ color: "#6a6a6a", fontSize: "1.2rem" }}>
                      XIRR: {investment?.xirr}
                    </p>
                  </div>
                </div>
                <div>
                  <p
                    style={{
                      fontSize: "1.4rem",
                      fontWeight: "500",
                      marginBottom: "1rem",
                      textAlign: "right",
                      color:
                        (
                          investment.nav * investment.units -
                          investment.invested_amount
                        ).toFixed(0) <= 0
                          ? "#eb5b3c"
                          : "var(--themeColor)",
                    }}
                  >
                    {sortOption.label === "returns"
                      ? `₹${(
                          investment.nav * investment.units -
                          investment.invested_amount
                        ).toFixed(0)}`
                      : `₹${(investment.nav * investment.units).toFixed(0)}`}
                  </p>
                  <p
                    style={{
                      fontSize: "1.4rem",
                      textAlign: "right",
                    }}
                  >
                    (
                    {(
                      ((investment.nav * investment.units -
                        investment.invested_amount) /
                        investment.invested_amount) *
                      100
                    ).toFixed(2)}
                    %)
                  </p>
                </div>
              </MobileInvestmentCardComp>
            );
          })}
          <BottomSheet
            containerStyles={{ padding: "3rem 2rem" }}
            show={showBottomSheet}
            close={() => {
              setMobileCancelSip(false);
              setShowBottomSheet(false);
              closeModal();
            }}
          >
            {mobileCancelSip ? (
              <CancelSip
                data={selectedInvestmentDetails}
                closeModal={() => {
                  setMobileCancelSip(false);
                }}
              />
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "5rem",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "1.4rem",
                      lineHeight: "1.5",
                    }}
                  >
                    {selectedInvestmentDetails?.scheme_name}
                  </p>
                  <p
                    style={{
                      fontSize: "1.4rem",
                      fontWeight: "500",
                      textAlign: "right",
                      color:
                        (
                          selectedInvestmentDetails?.nav *
                            selectedInvestmentDetails?.units -
                          selectedInvestmentDetails?.invested_amount
                        ).toFixed(0) <= 0
                          ? "#eb5b3c"
                          : "var(--themeColor)",
                    }}
                  >
                    ₹
                    {(
                      selectedInvestmentDetails?.nav *
                      selectedInvestmentDetails?.units
                    ).toFixed(0)}
                  </p>
                </div>
                <StyledAlignCenter
                  style={{
                    padding: "1.2rem 0",
                    marginBottom: "1.2rem",
                    borderBottom: "1px solid var(--secondaryLightGrey)",
                  }}
                  onClick={() =>
                    navigation("/investment-details", {
                      state: selectedInvestmentDetails,
                    })
                  }
                >
                  <FormatListBulleted />
                  <p>More Investment Details</p>
                </StyledAlignCenter>

                <div style={{ display: "flex", gap: "2rem" }}>
                  <Button
                    title={"REDEEM"}
                    isLoading={loadingMfData}
                    variant={"outlined"}
                    onClick={() =>
                      navigation("/redeem", {
                        state: { selectedInvestmentDetails, mfData },
                      })
                    }
                  />

                  <Button
                    title={"INVEST MORE"}
                    isLoading={loadingMfData}
                    onClick={() =>
                      navigation("/invest-more", {
                        state: selectedInvestmentDetails,
                      })
                    }
                  />
                </div>
                {selectedInvestmentDetails?.order_type === "SIP" && (
                  <Button
                    title={"CANCEL SIP"}
                    onClick={() => setMobileCancelSip(true)}
                    style={{ width: "100%", marginTop: "2rem" }}
                  />
                )}
              </>
            )}
          </BottomSheet>
          <BottomSheet
            containerStyles={{ padding: "3rem 2rem" }}
            show={showSortingOptions}
            close={() => setShowSortingOptions(false)}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <div
                onClick={() => setShowSortingOptions(true)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Sort style={{ fontSize: "2rem" }} />

                <p style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
                  Sort By
                </p>
              </div>
              <p
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "500",
                  color: "var(--themeColor)",
                }}
              >
                Manual Sort
              </p>
            </div>
            {SORT_OPTIONS.map((curr, i) => {
              return (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontSize: "1.4rem",
                    marginBottom: "1rem",
                  }}
                >
                  <p>{curr.label}</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <div
                      onClick={() =>
                        handleSort({
                          label: curr.property,
                          order: "DOWN",
                        })
                      }
                      style={{
                        padding: "1rem",
                        border:
                          sortOption.label === curr.property &&
                          sortOption.order === "DOWN"
                            ? "1px solid var(--themeColor)"
                            : "1px solid #ced4da",

                        color:
                          sortOption.label === curr.property &&
                          sortOption.order === "DOWN"
                            ? "var(--themeColor)"
                            : "",
                        borderRadius: "4px",
                      }}
                    >
                      <p>{curr.ascendingLabel}</p>
                    </div>
                    <div
                      onClick={() =>
                        handleSort({
                          label: curr.property,
                          order: "UP",
                        })
                      }
                      style={{
                        padding: "1rem",
                        border:
                          sortOption.label === curr.property &&
                          sortOption.order === "UP"
                            ? "1px solid var(--themeColor)"
                            : "1px solid #ced4da",

                        color:
                          sortOption.label === curr.property &&
                          sortOption.order === "UP"
                            ? "var(--themeColor)"
                            : "",
                        borderRadius: "4px",
                      }}
                    >
                      <p>{curr.descendingLabel}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </BottomSheet>
        </>
      </MobileContainer>
    </MobileMainContainer>
  );
};

export default Investments;

const CancelSip = ({
  onYes = () => {},
  onNo = () => {},
  closeModal = () => {},
  data,
}) => {
  const [errorState, setErrorState] = useState({ general: "" });

  const handleCancelSip = () => {
    return credentialAxios.post(API_ENDPOINTS.cancelSip, {
      sip_reg_number: data?.sip_regn_number,
    });
  };

  const {
    mutate: cancelSip,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(handleCancelSip, {
    onSuccess: (res) => {
      setErrorState({ general: "" });
    },
    onError: (err) => {
      const newErrors = mapServerErrorsToLocal(err, { general: "" });
      setErrorState(newErrors);
    },
  });

  return (
    <div style={{ padding: "2rem", marginTop: "3rem" }}>
      <ConditionalMutateLoader
        conditions={{
          isLoading,
          isError,
          isSuccess,
        }}
        renderInitial={() => (
          <div style={{ textAlign: "center" }}>
            <div style={{ width: "100%", textAlign: "right" }}>
              <CloseButton
                onClick={() => {
                  closeModal();
                }}
              />
            </div>
            <p style={{ fontSize: "2rem", fontWeight: "600" }}>
              Are you sure you want to Cancel SIP?
            </p>
            <p style={{ textAlign: "center" }}>({`${data.scheme_name}`})</p>
            <p>Folio Number: {data.folio_number}</p>

            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "1rem",
                margin: "1rem 0",
              }}
            >
              <Button title="YES" variant="outlined" onClick={cancelSip} />
              <Button
                title="NO"
                onClick={() => {
                  closeModal();
                }}
              />
            </div>
          </div>
        )}
        renderLoading={() => (
          <StyledCentering style={{ flexDirection: "column" }}>
            <Note note={"Please do not refresh or close this dialog"} />

            <p>Cancelling SIP</p>
            <MiniLoader />
          </StyledCentering>
        )}
        renderError={() => (
          <StyledCentering style={{ flexDirection: "column" }}>
            <CloseButton
              onClick={() => {
                closeModal();
                window.location.reload();
              }}
            />
            <p>{errorState.general}</p>
          </StyledCentering>
        )}
        renderSuccess={() => {
          return (
            <StyledCentering style={{ flexDirection: "column" }}>
              <CloseButton
                onClick={() => {
                  closeModal();
                  window.location.reload();
                }}
              />
              <p>Your SIP is cancelled</p>
            </StyledCentering>
          );
        }}
      />
    </div>
  );
};
